import React from "react";
import Home from "./views/home"
import Form from './views/form'
import ViewportProvider from "./hooks/viewportProvider"
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PageNotFound from "./components/pageNotFound";


function App() {
  return (
    <ViewportProvider>
      <BrowserRouter basename="" >
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/:param?" component={PageNotFound} />

        </Switch>
      </BrowserRouter>
    </ViewportProvider>
  )
}

export default App;
