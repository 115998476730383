import React from 'react';
import './style.css'
import styled from "styled-components";
import menulist from "@material-ui/core/MenuList";
import { MenuItem } from "@material-ui/core";
import button from '@material-ui/core/Button'


export const COLORS = {
    darkGreen: "#175919",
    mediumGreen: "#4dac3e",
    brightGreen: "#4bd84f",
    darkGray: "#30403a",
    mediumGray:"#eee",
    brightGray: "#f6f6f6"
}

export const FONTS = {
    montserrat: "'Montserrat', sans-serif",
    title: "'Roboto', sans-serif",
    paragrafo: "'Open Sans', sans-serif" 
}


export const MenuList = styled(menulist)`
    display:flex;
`

export const MenuLink = styled(MenuItem)`
  color: ${COLORS.darkGray};
  text-decoration: none !important;
  padding-top:14px;
  font-family:${FONTS.menu};

  &:hover{
      background:rgba(0,0,0,0);
      color:${COLORS.brightGreen};
  }
`;

export const Row = styled.div`
  display:flex;
  padding: ${props => props.padding};
  flex-direction:${props => props.flexDirection};
  justify-content:${props => props.justifyContent};
  align-items:${props => props.alignItems};
  margin: ${props => props.margin};
`

export const Card = styled.div`
  width:${props => props.width};
  padding:${props => props.padding};
  background:${props => props.color ? props.color : COLORS.darkGreen};
  color:white;
  text-align:center;
  border-radius:30px;
  font-family:${FONTS.montserrat};
  font-weight:600;
`

export const SectionTitle = styled.div`
  width:${props => props.width};
  padding-right:20px;
  font-family: ${FONTS.montserrat};
  color: ${props => props.color};
  font-size: 1.4rem;
  font-weight:800;
  text-align:center;
  text-transform:uppercase;
`

export const SectionText = styled.div`
  width:${props => props.width};
  font-family: ${FONTS.montserrat};
  color: ${props => props.color};
  margin: ${props => props.margin};
  font-size: 1rem;
  font-weight:500;
  text-align:center;
  text-align:justify;
`

export const FormContainer = styled.div`
    width:70%;
    padding:50px;
    border-radius:30px;
`

export const VideosCard = styled.div`
  background:white;
  margin:100px auto 0 auto;
  padding:40px;
  border-radius:15px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.25);
`

export const Button = styled(button)`
    background-color:${props => props.backgroundColor};
    color:white; 
    padding:10px 27px;

    &:hover{
        background-color:${props => props.backgroundHover};
    }
`
