import React from 'react';
import styled from "styled-components"
import {COLORS, FONTS, Row} from '../styles/styles'
import {UseViewport} from '../hooks/viewportProvider'

const Footer = () => {
    const {width} = UseViewport();

    const Container = styled.div`
        width:${width<990 ? "95": "60"}%;
        padding:20px 0;
        margin:auto;
    `

    const BgContainer = styled.div`
        background:${COLORS.darkGray};
    `

    const P = styled.p`
        font-family:${FONTS.paragrafo};
        color:white;
        font-size:1rem;
    `

    return(
        <BgContainer>
            <Container>
                <Row justifyContent="space-between">
                    <P> © 2020 Seja Broker</P>
                    <P> Desenvolvido por Storm Studio</P>
                </Row>
            </Container>
        </BgContainer>
    );
}

export default Footer;