import React from "react";
import styled from "styled-components";
import {
  handleCpfChange,
  handleIdhange,
  handleDateChange,
} from "../utils/formFunctions";

const numeros = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const MediadorForm = ({ formik, mediadorFinished, setMediadorFinished, handleFinished }) => {
  return (
    <Table>
      <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
        <th colspan="6">Dados Cadastrais do Mediador:{" "}
          <input
            id="mediador_check_true"
            name="mediador_check"
            type="radio"
            value="true"
            onChange={formik.handleChange}
            checked={formik.values.mediador_check == "true"}
          ></input>
          <label style={{ marginRight: "20px" }} htmlFor="mediador_check_true">
            Cadastrar mediador
          </label>
          <input
            id="mediador_check_false"
            name="mediador_check"
            type="radio"
            value="false"
            onChange={formik.handleChange}
            checked={formik.values.mediador_check == "false"}
          ></input>
          <label htmlFor="mediador_check_false"> Não cadastrar mediador</label>
        </th>

      </tr>
      <tr>
        <th colspan="3">
          <label htmlFor="mediador_name">Nome Completo</label>
        </th>
        <th colspan="3">
          <label htmlFor="mediador_profession">Profissão</label>
        </th>
      </tr>
      <tr>
        <td colspan="3">
          <Input
            htmlFor="mediador_name"
            name="mediador_name"
            value={formik.values.mediador_name}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td colspan="3">
          <Input
            htmlFor="mediador_profession"
            name="mediador_profession"
            value={formik.values.mediador_profession}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
            value={formik.values.profession}
          />
        </td>
      </tr>

      <tr>
        <th>
          <label htmlFor="mediador_data_de_nascimento">
            Data de Nascimento
          </label>
        </th>
        <th>
          <label htmlFor="mediador_nacionalidade">Nacionalidade</label>
        </th>
        <th>
          <label htmlFor="mediador_local_de_nascimento">
            Local de Nascimento
          </label>
        </th>
        <th>
          <label htmlFor="mediador_uf">UF</label>
        </th>
        <th>
          <label htmlFor="mediador_estado_civil">Estado Civil</label>
        </th>
        <th>
          <label htmlFor="mediador_sexo">Sexo</label>
        </th>
      </tr>

      <tr>
        <td>
          <Input
            htmlFor="mediador_data_de_nascimento"
            name="mediador_data_de_nascimento"
            value={formik.values.mediador_data_de_nascimento}
            type="text"
            onChange={(event) => { handleDateChange(event, formik); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_nacionalidade"
            name="mediador_nacionalidade"
            value={formik.values.mediador_nacionalidade}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_local_de_nascimento"
            name="mediador_local_de_nascimento"
            value={formik.values.mediador_local_de_nascimento}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_uf"
            name="mediador_uf"
            value={formik.values.mediador_uf}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_estado_civil"
            name="mediador_estado_civil"
            value={formik.values.mediador_estado_civil}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_sexo"
            name="mediador_sexo"
            value={formik.values.mediador_sexo}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="mediador_cpf">CPF</label>
        </th>
        <th>
          <label htmlFor="mediador_identidade">
            Nº do Documento de Identidade
          </label>
        </th>
        <th>
          <label htmlFor="mediador_data_emissao">Data de Emissão</label>
        </th>
        <th>
          <label htmlFor="mediador_orgao_emissor">Órgão Emissor</label>
        </th>
        <th>
          <label htmlFor="mediador_uf_emissor">UF-Emissor</label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor="mediador_cpf"
            name="mediador_cpf"
            value={formik.values.mediador_cpf}
            type="text"
            onChange={(event) => { handleCpfChange(event, formik); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_identidade"
            name="mediador_identidade"
            value={formik.values.mediador_identidade}
            type="text"
            onChange={(event) => { handleIdhange(event, formik); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_data_emissao"
            name="mediador_data_emissao"
            value={formik.values.mediador_data_emissao}
            type="text"
            onChange={(event) => { handleDateChange(event, formik); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_orgao_emissor"
            name="mediador_orgao_emissor"
            value={formik.values.mediador_orgao_emissor}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_uf_emissor"
            name="mediador_uf_emissor"
            value={formik.values.mediador_uf_emissor}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="mediador_endereco_residencial">
            Endereço Residencial (Logradouro)
          </label>
        </th>
        <th colspan="2">
          <label htmlFor="mediador_numero">Número</label>
        </th>
        <th colspan="2">
          <label htmlFor="mediador_complemento">Complemento</label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor="mediador_endereco_residencial"
            name="mediador_endereco_residencial"
            value={formik.values.mediador_endereco_residencial}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="mediador_numero"
            name="mediador_numero"
            value={formik.values.mediador_numero}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="mediador_complemento"
            name="mediador_complemento"
            value={formik.values.mediador_complemento}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="mediador_bairro">Bairro</label>
        </th>
        <th>
          <label htmlFor="mediador_cidade">Cidade</label>
        </th>
        <th>
          <label htmlFor="mediador_uf">UF</label>
        </th>
        <th>
          <label htmlFor="mediador_pais">País</label>
        </th>
        <th>
          <label htmlFor="mediador_cep">CEP</label>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          <Input
            htmlFor="mediador_bairro"
            name="mediador_bairro"
            value={formik.values.mediador_bairro}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_cidade"
            name="mediador_cidade"
            value={formik.values.mediador_cidade}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_uf"
            name="mediador_uf"
            value={formik.values.mediador_uf}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_pais"
            name="mediador_pais"
            value={formik.values.mediador_pais}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="mediador_cep"
            name="mediador_cep"
            value={formik.values.mediador_cep}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="mediador_telefone">Telefone (DDD + Número)</label>
        </th>
        <th colspan="2">
          <label htmlFor="mediador_fax">Fax (DDD + Número)</label>
        </th>
        <th colspan="2">
          <label htmlFor="mediador_email">E-mail</label>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          <Input
            htmlFor="mediador_telefone"
            name="mediador_telefone"
            value={formik.values.mediador_telefone}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="mediador_fax"
            name="mediador_fax"
            value={formik.values.mediador_fax}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="mediador_email"
            name="mediador_email"
            value={formik.values.mediador_email}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "mediador_", mediadorFinished, setMediadorFinished) }}
          />
        </td>
      </tr>
    </Table>

  );
};

const Table = styled.table`
  width: 100%;
  th,
  td {
    margin: 0;
  }
  th {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
`;

export default MediadorForm;
