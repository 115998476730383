import React from 'react';
import button from '@material-ui/core/Button'
import { COLORS, FONTS, Row } from '../styles/styles'
import styled from 'styled-components';
import { Link } from 'react-scroll';

const BannerRight = (props) => {
    const Container = styled.div`
        width:${props.windowWidth > 990 ? "60%" : "100%"};
        height:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        float:left;
    `

    return (
        <Container>
            <Row flexDirection="column" alignItems={props.windowWidth > 990 ? "end" : "center"} style={{ width: "400px" }}>
                <p style={{
                    fontFamily: FONTS.montserrat,
                    fontSize: "1.2rem",
                    color: COLORS.brightGray,
                    fontWeight: 600,
                    textAlign: "justify"
                }}>O Seja Broker é uma ferramenta de publicações de cursos promovidos pela Bolsa Brasileira de Mercadorias e por parceiros da Árbol Commodities Broker.</p>
                <Link to="contact" smooth={true} style={{ cursor: "pointer" }}><Button>MATRICULE-SE JÁ</Button></Link>
            </Row>
        </Container>
    )
}

const Button = styled(button)`
    border:1px solid ${COLORS.brightGray} !important;
    width:180px;    

    *{
        color: ${COLORS.brightGray};
    }
`

export default BannerRight;