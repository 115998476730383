import React from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import axios from "axios";
import SocioForm from "../components/socioForm";
import ParceiroForm from "../components/parceiroForm";
import EmpresaForm from "../components/empresaForm";
import MediadorForm from "../components/mediadorForm";
import { Redirect } from 'react-router-dom';

const validate = (values) => {
  let errors = false;
  for (var key in values) {
    if (
      !key.includes("socio2") &&
      (values[key] === null || values[key] == [""])
    ) {
      errors = true;
    }
  }

  console.log(errors);
  if (errors) {
    alert("Todos campos são obrigatórios!");
  }
};

const FormView = () => {
  const [parceiroFinished, setParceiroFinished] = React.useState(false)
  const [socioFinished, setSocioFinished] = React.useState(false)
  const [empresaFinished, setEmpresaFinished] = React.useState(false)
  const [mediadorFinished, setMediadorFinished] = React.useState(false)
  const [homeRedirect, setHomeRedirect] = React.useState(false)

  const handleFinished = (event, prefix, setVar, setFunction) => {
    let name = event.target.name
    let value = event.target.value
    let falseCondition = value.trim() == "" || value == null

    if (falseCondition) {
      setFunction(false);
    } else if (!setVar) {
      let flag = true;
      for (const key in formik.values) {
        if (key.includes(prefix) && key != name && !falseCondition && (formik.values[key].trim() == "" || formik.values[key] == null)) {
          flag = false;
        }
      }
      setFunction(flag);
    }
  }

  const formik = useFormik({
    initialValues: {
      socio_procurador: null,
      parceiro_nome: "",
      parceiro_profissao: "",
      parceiro_data_de_nascimento: "",
      parceiro_nacionalidade: "",
      parceiro_local_de_nascimento: "",
      parceiro_uf: "",
      parceiro_estado_civil: "",
      parceiro_sexo: "",
      parceiro_cpf: "",
      parceiro_identidade: "",
      parceiro_data_emissao: "",
      parceiro_orgao_emissor: "",
      parceiro_uf_emissor: "",
      parceiro_endereco_residencial: "",
      parceiro_numero: "",
      parceiro_complemento: "",
      parceiro_bairro: "",
      parceiro_cidade: "",
      parceiro_nascimento_uf: "",
      parceiro_pais: "",
      parceiro_cep: "",
      parceiro_telefone: "",
      parceiro_fax: "",
      parceiro_email: "",
      empresa_nome: "",
      empresa_ramo: "",
      empresa_cnpj: "",
      empresa_inscricao: "",
      empresa_data_fundacao: "",
      empresa_endereco: "",
      empresa_numero: "",
      empresa_complemento: "",
      empresa_bairro: "",
      empresa_cidade: "",
      empresa_uf: "",
      empresa_pais: "",
      empresa_cep: "",
      socio1_name: "",
      socio1_profession: "",
      socio1_data_de_nascimento: "",
      socio1_nacionalidade: "",
      socio1_local_de_nascimento: "",
      socio1_uf: "",
      socio1_estado_civil: "",
      socio1_sexo: "",
      socio1_cpf: "",
      socio1_identidade: "",
      socio1_data_emissao: "",
      socio1_orgao_emissor: "",
      socio1_uf_emissor: "",
      socio1_endereco_residencial: "",
      socio1_numero: "",
      socio1_complemento: "",
      socio1_bairro: "",
      socio1_cidade: "",
      socio1_uf: "",
      socio1_pais: "",
      socio1_cep: "",
      socio1_telefone: "",
      socio1_fax: "",
      socio1_email: "",
      socio2_name: "",
      socio2_profession: "",
      socio2_data_de_nascimento: "",
      socio2_nacionalidade: "",
      socio2_local_de_nascimento: "",
      socio2_uf: "",
      socio2_estado_civil: "",
      socio2_sexo: "",
      socio2_cpf: "",
      socio2_identidade: "",
      socio2_data_emissao: "",
      socio2_orgao_emissor: "",
      socio2_uf_emissor: "",
      socio2_endereco_residencial: "",
      socio2_numero: "",
      socio2_complemento: "",
      socio2_bairro: "",
      socio2_cidade: "",
      socio2_uf: "",
      socio2_pais: "",
      socio2_cep: "",
      socio2_telefone: "",
      socio2_fax: "",
      socio2_email: "",
      mediador_name: "",
      mediador_profession: "",
      mediador_data_de_nascimento: "",
      mediador_nacionalidade: "",
      mediador_local_de_nascimento: "",
      mediador_uf: "",
      mediador_estado_civil: "",
      mediador_sexo: "",
      mediador_cpf: "",
      mediador_identidade: "",
      mediador_data_emissao: "",
      mediador_orgao_emissor: "",
      mediador_uf_emissor: "",
      mediador_endereco_residencial: "",
      mediador_numero: "",
      mediador_complemento: "",
      mediador_bairro: "",
      mediador_cidade: "",
      mediador_uf: "",
      mediador_pais: "",
      mediador_cep: "",
      mediador_telefone: "",
      mediador_fax: "",
      mediador_email: "",
      isoladamente_socios: 0,
      mediador_check: "false"
    },
    /* initialValues: {
      socio_procurador: null,
      parceiro_nome: "Lucas Ramalho",
      parceiro_profissao: "Desenvolvedor",
      parceiro_data_de_nascimento: "25/02/1997",
      parceiro_nacionalidade: "Brasileiro",
      parceiro_local_de_nascimento: "Niterói",
      parceiro_uf: "RJ",
      parceiro_estado_civil: "Solteiro",
      parceiro_sexo: "Masculino",
      parceiro_cpf: "128.600.847-62",
      parceiro_identidade: "28.628.041-7",
      parceiro_data_emissao: "00/00/0000",
      parceiro_orgao_emissor: "DETRAN",
      parceiro_uf_emissor: "RJ",
      parceiro_endereco_residencial: "Rua Doutor Cássio Rother do Amaral",
      parceiro_numero: "376",
      parceiro_complemento: "casa 2",
      parceiro_bairro: "Itaipu",
      parceiro_cidade: "Niterói",
      parceiro_nascimento_uf: "RJ",
      parceiro_pais: "Brasil",
      parceiro_cep: "23444-150",
      parceiro_telefone: "(21)997889757",
      parceiro_fax: "00000",
      parceiro_email: "lucas-rls@hotmail.com",
      empresa_nome: "LINUS CONSULTORIA E PARTICIPAÇÃO LTDA.",
      empresa_ramo: "Consultoria",
      empresa_cnpj: "14.186.567/0001-94",
      empresa_inscricao: "empresa_inscricao",
      empresa_data_fundacao: "empresa_data_fundacao",
      empresa_endereco: "Rua Uruguai",
      empresa_numero: "371",
      empresa_complemento: "Conjunto 401",
      empresa_bairro: "Tijuca",
      empresa_cidade: "Rio de Janeiro",
      empresa_uf: "RJ",
      empresa_pais: "empresa_pais",
      empresa_cep: "20.000-00",
      socio1_name: "RICARDO CALMON DE OLIVEIRA",
      socio1_profession: "Agente de Investimento",
      socio1_data_de_nascimento: "socio1_data_de_nascimento",
      socio1_nacionalidade: "brasileiro",
      socio1_local_de_nascimento: "socio1_local_de_nascimento",
      socio1_uf: "socio1_uf",
      socio1_estado_civil: "casado",
      socio1_sexo: "socio1_sexo",
      socio1_cpf: "753.133.167-53",
      socio1_identidade: "11743726-9",
      socio1_data_emissao: "socio1_data_emissao",
      socio1_orgao_emissor: "DETRAN – Diretoria de Identificação Civil",
      socio1_uf_emissor: "socio1_uf_emissor",
      socio1_endereco_residencial: "socio1_endereco_residencial",
      socio1_numero: "socio1_numero",
      socio1_complemento: "socio1_complemento",
      socio1_bairro: "socio1_bairro",
      socio1_cidade: "socio1_cidade",
      socio1_uf: "socio1_uf",
      socio1_pais: "socio1_pais",
      socio1_cep: "socio1_cep",
      socio1_telefone: "socio1_telefone",
      socio1_fax: "socio1_fax",
      socio1_email: "socio1_email",
      socio2_name: "socio2_name",
      socio2_profession: "socio2_profession",
      socio2_data_de_nascimento: "socio2_data_de_nascimento",
      socio2_nacionalidade: "socio2_nacionalidade",
      socio2_local_de_nascimento: "socio2_local_de_nascimento",
      socio2_uf: "socio2_uf",
      socio2_estado_civil: "socio2_estado_civil",
      socio2_sexo: "socio2_sexo",
      socio2_cpf: "socio2_cpf",
      socio2_identidade: "socio2_identidade",
      socio2_data_emissao: "socio2_data_emissao",
      socio2_orgao_emissor: "socio2_orgao_emissor",
      socio2_uf_emissor: "socio2_uf_emissor",
      socio2_endereco_residencial: "socio2_endereco_residencial",
      socio2_numero: "socio2_numero",
      socio2_complemento: "socio2_complemento",
      socio2_bairro: "socio2_bairro",
      socio2_cidade: "socio2_cidade",
      socio2_uf: "socio2_uf",
      socio2_pais: "socio2_pais",
      socio2_cep: "socio2_cep",
      socio2_telefone: "socio2_telefone",
      socio2_fax: "socio2_fax",
      socio2_email: "socio2_email",
      mediador_name: "mediador_name",
      mediador_profession: "mediador_profession",
      mediador_data_de_nascimento: "mediador_data_de_nascimento",
      mediador_nacionalidade: "mediador_nacionalidade",
      mediador_local_de_nascimento: "mediador_local_de_nascimento",
      mediador_uf: "mediador_uf",
      mediador_estado_civil: "mediador_estado_civil",
      mediador_sexo: "mediador_sexo",
      mediador_cpf: "mediador_cpf",
      mediador_identidade: "mediador_identidade",
      mediador_data_emissao: "mediador_data_emissao",
      mediador_orgao_emissor: "mediador_orgao_emissor",
      mediador_uf_emissor: "mediador_uf_emissor",
      mediador_endereco_residencial: "mediador_endereco_residencial",
      mediador_numero: "mediador_numero",
      mediador_complemento: "mediador_complemento",
      mediador_bairro: "mediador_bairro",
      mediador_cidade: "mediador_cidade",
      mediador_uf: "mediador_uf",
      mediador_pais: "mediador_pais",
      mediador_cep: "mediador_cep",
      mediador_telefone: "mediador_telefone",
      mediador_fax: "mediador_fax",
      mediador_email: "mediador_email",
      isoladamente_socios: 0,
      mediador_check: "false"
    }, */
    onSubmit: (values) => {
      //validate(values);
      let form = new FormData();
      form.append("parceiro_nome", values.parceiro_nome);
      form.append("parceiro_profissao", values.parceiro_profissao);
      form.append(
        "parceiro_data_de_nascimento",
        values.parceiro_data_de_nascimento
      );
      form.append("parceiro_nacionalidade", values.parceiro_nacionalidade);
      form.append(
        "parceiro_local_de_nascimento",
        values.parceiro_local_de_nascimento
      );
      form.append("parceiro_uf", values.parceiro_uf);
      form.append("parceiro_estado_civil", values.parceiro_estado_civil);
      form.append("parceiro_sexo", values.parceiro_sexo);
      form.append("parceiro_cpf", values.parceiro_cpf);
      form.append("parceiro_identidade", values.parceiro_identidade);
      form.append("parceiro_data_emissao", values.parceiro_data_emissao);
      form.append("parceiro_orgao_emissor", values.parceiro_orgao_emissor);
      form.append("parceiro_uf_emissor", values.parceiro_uf_emissor);
      form.append(
        "parceiro_endereco_residencial",
        values.parceiro_endereco_residencial
      );
      form.append("parceiro_numero", values.parceiro_numero);
      form.append("parceiro_complemento", values.parceiro_complemento);
      form.append("parceiro_bairro", values.parceiro_bairro);
      form.append("parceiro_cidade", values.parceiro_cidade);
      form.append("parceiro_nascimento_uf", values.parceiro_nascimento_uf);
      form.append("parceiro_pais", values.parceiro_pais);
      form.append("parceiro_cep", values.parceiro_cep);
      form.append("parceiro_telefone", values.parceiro_telefone);
      form.append("parceiro_fax", values.parceiro_fax);
      form.append("parceiro_email", values.parceiro_email);
      form.append("empresa_nome", values.empresa_nome);
      form.append("empresa_ramo", values.empresa_ramo);
      form.append("empresa_cnpj", values.empresa_cnpj);
      form.append("empresa_inscricao", values.empresa_inscricao);
      form.append("empresa_data_fundacao", values.empresa_data_fundacao);
      form.append("empresa_endereco", values.empresa_endereco);
      form.append("empresa_numero", values.empresa_numero);
      form.append("empresa_complemento", values.empresa_complemento);
      form.append("empresa_bairro", values.empresa_bairro);
      form.append("empresa_cidade", values.empresa_cidade);
      form.append("empresa_uf", values.empresa_uf);
      form.append("empresa_pais", values.empresa_pais);
      form.append("empresa_cep", values.empresa_cep);
      if (values.socio_procurador == "socio") {
        form.append("socio1_name", values.parceiro_name);
        form.append("socio1_profession", values.parceiro_profession);
        form.append(
          "socio1_data_de_nascimento",
          values.parceiro_data_de_nascimento
        );
        form.append("socio1_nacionalidade", values.parceiro_nacionalidade);
        form.append(
          "socio1_local_de_nascimento",
          values.parceiro_local_de_nascimento
        );
        form.append("socio1_uf", values.parceiro_uf);
        form.append("socio1_estado_civil", values.parceiro_estado_civil);
        form.append("socio1_sexo", values.parceiro_sexo);
        form.append("socio1_cpf", values.parceiro_cpf);
        form.append("socio1_identidade", values.parceiro_identidade);
        form.append("socio1_data_emissao", values.parceiro_data_emissao);
        form.append("socio1_orgao_emissor", values.parceiro_orgao_emissor);
        form.append("socio1_uf_emissor", values.parceiro_uf_emissor);
        form.append(
          "socio1_endereco_residencial",
          values.parceiro_endereco_residencial
        );
        form.append("socio1_numero", values.parceiro_numero);
        form.append("socio1_complemento", values.parceiro_complemento);
        form.append("socio1_bairro", values.parceiro_bairro);
        form.append("socio1_cidade", values.parceiro_cidade);
        form.append("socio1_uf", values.parceiro_uf);
        form.append("socio1_pais", values.parceiro_pais);
        form.append("socio1_cep", values.parceiro_cep);
        form.append("socio1_telefone", values.parceiro_telefone);
        form.append("socio1_fax", values.parceiro_fax);
        form.append("socio1_email", values.parceiro_email);
      } else {
        form.append("socio1_name", values.socio1_name);
        form.append("socio1_profession", values.socio1_profession);
        form.append(
          "socio1_data_de_nascimento",
          values.socio1_data_de_nascimento
        );
        form.append("socio1_nacionalidade", values.socio1_nacionalidade);
        form.append(
          "socio1_local_de_nascimento",
          values.socio1_local_de_nascimento
        );
        form.append("socio1_uf", values.socio1_uf);
        form.append("socio1_estado_civil", values.socio1_estado_civil);
        form.append("socio1_sexo", values.socio1_sexo);
        form.append("socio1_cpf", values.socio1_cpf);
        form.append("socio1_identidade", values.socio1_identidade);
        form.append("socio1_data_emissao", values.socio1_data_emissao);
        form.append("socio1_orgao_emissor", values.socio1_orgao_emissor);
        form.append("socio1_uf_emissor", values.socio1_uf_emissor);
        form.append(
          "socio1_endereco_residencial",
          values.socio1_endereco_residencial
        );
        form.append("socio1_numero", values.socio1_numero);
        form.append("socio1_complemento", values.socio1_complemento);
        form.append("socio1_bairro", values.socio1_bairro);
        form.append("socio1_cidade", values.socio1_cidade);
        form.append("socio1_uf", values.socio1_uf);
        form.append("socio1_pais", values.socio1_pais);
        form.append("socio1_cep", values.socio1_cep);
        form.append("socio1_telefone", values.socio1_telefone);
        form.append("socio1_fax", values.socio1_fax);
      }
      form.append("socio2_name", values.socio2_name);
      form.append("socio2_profession", values.socio2_profession);
      form.append(
        "socio2_data_de_nascimento",
        values.socio2_data_de_nascimento
      );
      form.append("socio2_nacionalidade", values.socio2_nacionalidade);
      form.append(
        "socio2_local_de_nascimento",
        values.socio2_local_de_nascimento
      );
      form.append("socio2_uf", values.socio2_uf);
      form.append("socio2_estado_civil", values.socio2_estado_civil);
      form.append("socio2_sexo", values.socio2_sexo);
      form.append("socio2_cpf", values.socio2_cpf);
      form.append("socio2_identidade", values.socio2_identidade);
      form.append("socio2_data_emissao", values.socio2_data_emissao);
      form.append("socio2_orgao_emissor", values.socio2_orgao_emissor);
      form.append("socio2_uf_emissor", values.socio2_uf_emissor);
      form.append(
        "socio2_endereco_residencial",
        values.socio2_endereco_residencial
      );
      form.append("socio2_numero", values.socio2_numero);
      form.append("socio2_complemento", values.socio2_complemento);
      form.append("socio2_bairro", values.socio2_bairro);
      form.append("socio2_cidade", values.socio2_cidade);
      form.append("socio2_uf", values.socio2_uf);
      form.append("socio2_pais", values.socio2_pais);
      form.append("socio2_cep", values.socio2_cep);
      form.append("socio2_telefone", values.socio2_telefone);
      form.append("socio2_fax", values.socio2_fax);
      form.append("socio2_email", values.socio2_email);
      form.append("mediador_name", values.mediador_name);
      form.append("mediador_profession", values.mediador_profession);
      form.append(
        "mediador_data_de_nascimento",
        values.mediador_data_de_nascimento
      );
      form.append("mediador_nacionalidade", values.mediador_nacionalidade);
      form.append(
        "mediador_local_de_nascimento",
        values.mediador_local_de_nascimento
      );
      form.append("mediador_uf", values.mediador_uf);
      form.append("mediador_estado_civil", values.mediador_estado_civil);
      form.append("mediador_sexo", values.mediador_sexo);
      form.append("mediador_cpf", values.mediador_cpf);
      form.append("mediador_identidade", values.mediador_identidade);
      form.append("mediador_data_emissao", values.mediador_data_emissao);
      form.append("mediador_orgao_emissor", values.mediador_orgao_emissor);
      form.append("mediador_uf_emissor", values.mediador_uf_emissor);
      form.append(
        "mediador_endereco_residencial",
        values.mediador_endereco_residencial
      );
      form.append("mediador_numero", values.mediador_numero);
      form.append("mediador_complemento", values.mediador_complemento);
      form.append("mediador_bairro", values.mediador_bairro);
      form.append("mediador_cidade", values.mediador_cidade);
      form.append("mediador_uf", values.mediador_uf);
      form.append("mediador_pais", values.mediador_pais);
      form.append("mediador_cep", values.mediador_cep);
      form.append("mediador_telefone", values.mediador_telefone);
      form.append("mediador_fax", values.mediador_fax);
      form.append("mediador_email", values.mediador_email);
      form.append("socio_procurador", values.socio_procurador);
      form.append("mediador_check", values.mediador_check)

      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();

      today = mm + '/' + dd + '/' + yyyy;
      form.append("date", today);

      axios
        .post("http://www.sejabroker.com.br/api/generate_contract.php", form, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data == "sucesso") {
            alert("Dados enviados com sucesso!")
            setHomeRedirect(true);
          } else {
            alert("Ocorreu algum erro, tente novamente mais tarde!")
          }
        });
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <ParceiroForm handleFinished={handleFinished} parceiroFinished={parceiroFinished} setParceiroFinished={setParceiroFinished} formik={formik}></ParceiroForm>
        {
          parceiroFinished
            ?
            formik.values.socio_procurador ? (
              <EmpresaForm handleFinished={handleFinished} empresaFinished={empresaFinished} setEmpresaFinished={setEmpresaFinished} formik={formik}></EmpresaForm>
            ) : (
                <Table>
                  <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                    <th colspan="5">
                      <span style={{ marginRight: "30px" }}>
                        Dados Cadastrais da Empresa do Parceiro:{" "}
                      </span>
                      <input
                        id="socio_check"
                        name="socio_procurador"
                        type="radio"
                        value="socio"
                        onChange={formik.handleChange}
                      ></input>
                      <label style={{ marginRight: "20px" }} htmlFor="socio">
                        Sou sócio
                  </label>
                      <input
                        id="procurador_check"
                        name="socio_procurador"
                        type="radio"
                        value="procurador"
                        onChange={formik.handleChange}
                      ></input>
                      <label htmlFor="procurador_check"> Sou procurador</label>
                    </th>
                  </tr>
                </Table>
              )
            :
            null
        }

        {empresaFinished && formik.values.socio_procurador === "socio" ? (
          <Table>
            <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
              <th colspan="5">
                <span style={{ marginRight: "30px" }}>
                  Como figura a  Cláusula de Administração da Empresa:
              </span>
                <input
                  id="isoladamente_check"
                  name="isoladamente_socios"
                  type="radio"
                  value={1}
                  onChange={formik.handleChange}
                ></input>
                <label style={{ marginRight: "20px" }} htmlFor="isoladamente_check">
                  Isoladamente
            </label>
                <input
                  id="socios_check"
                  name="isoladamente_socios"
                  type="radio"
                  value={2}
                  onChange={formik.handleChange}
                ></input>
                <label htmlFor="socios_check"> Em conjunto com outro Sócio</label>
              </th>
            </tr>
          </Table>
        ) : null}

        {
          empresaFinished && formik.values.socio_procurador === "procurador"
            ?
            <SocioForm formik={formik} handleFinished={(event) => { handleFinished(event, "socio1_", socioFinished, setSocioFinished) }} socioNumber="1" title="Procurador"></SocioForm>
            :
            null
        }

        {
          formik.values.isoladamente_socios > 0 && formik.values.socio_procurador === "socio"
            ?
            formik.values.isoladamente_socios == 1
              ?
              null
              :
              <div>
                <SocioForm formik={formik} handleFinished={(event) => { formik.handleChange(event); handleFinished(event, "socio2_", socioFinished, setSocioFinished) }} socioNumber="2"></SocioForm>
              </div>
            :
            null
        }
        {
          parceiroFinished && (socioFinished || formik.values.isoladamente_socios == 1) && empresaFinished && formik.values.mediador_check == "false" ?
            <Table>
              <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                <th colspan="6">Dados Cadastrais do Mediador:{" "}
                  <input
                    id="mediador_check_true"
                    name="mediador_check"
                    type="radio"
                    value="true"
                    onChange={formik.handleChange}
                    checked={formik.values.mediador_check == "true"}
                  ></input>
                  <label style={{ marginRight: "20px" }} htmlFor="mediador_check_true">
                    Cadastrar mediador
          </label>
                  <input
                    id="mediador_check_false"
                    name="mediador_check"
                    type="radio"
                    value="false"
                    onChange={formik.handleChange}
                    checked={formik.values.mediador_check == "false"}
                  ></input>
                  <label htmlFor="mediador_check_false"> Não cadastrar mediador</label>
                </th>

              </tr>
            </Table>
            :
            null
        }
        {
          parceiroFinished && (socioFinished || formik.values.isoladamente_socios == 1) && empresaFinished && formik.values.mediador_check == "true"
            ?
            <MediadorForm formik={formik} mediadorFinished={mediadorFinished} setMediadorFinished={setMediadorFinished} handleFinished={handleFinished}></MediadorForm>
            :
            null
        }
        {
          parceiroFinished && (socioFinished || formik.values.isoladamente_socios == 1) && empresaFinished
            ?
            <SubmitButton type="submit">Enviar</SubmitButton>
            :
            null
        }
        {
          !parceiroFinished || !socioFinished || !empresaFinished
            ?
            <p style={{ textAlign: "center" }}>Preencha tudo para continuar...</p>
            :
            null
        }
      </form>
      { homeRedirect ? (<Redirect push to="/" />) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  margin: auto;
  overflow-x: hidden;
`;

export const Row = styled.div`
  display: flex;
  padding: ${(props) => props.padding};
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  float: left;
`;

const Table = styled.table`
  width: 100%;
  th,
  td {
    margin: 0;
  }
  th {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
`;

const ErrorField = styled.div`
  color: red;
`;

const SubmitButton = styled.button`
  margin: 20px auto 20px auto;
  display: block;
  width: 200px;
  padding: 10px;
`;

export default FormView;
