import React from 'react'
import { Row } from '../styles/styles'

function ServiceBox(props) {
    console.log(props)
    return (
        <Row style={{ width: props.width, opacity: props.opacity }} flexDirection="column" justifyContent="center" alignItems="center">
            {
                props.video != ""
                    ?
                    <video width="90%" height="300px" controls>
                        <source src={props.video} type="video/mp4"></source>
                    </video>
                    :
                    <div style={{
                        width: "90%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden"
                    }}>
                        <a target="_blank" href={props.link}><img width="90%" src={props.image}></img></a>
                    </div>
            }

            <p><a target="_blank" href={props.link != "" ? props.link : null}>{props.name}</a></p>
            <p style={{ textAlign: "justify" }}>{props.description}</p>
        </Row>
    )
}

export default ServiceBox;