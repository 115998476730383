import React from 'react'
import styled from 'styled-components'
import { Row, COLORS } from '../styles/styles'
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const VideosArrows = (props) => {
    const Back = styled(ButtonBack)`
        position: absolute;
        background:none;
        border:none;
        pointer-events: all;

        left:0;
        &{
            color:${COLORS.darkGray} !important;
        }
        &[disabled]{
            color:#dbdbdb !important;
        }
        .MuiSvgIcon-root{
            font-size:3rem !important;
        }
    `
    const Next = styled(ButtonNext)`
        position: absolute;
        background:none;
        border:none;
        right:0;
        pointer-events: all;
        
        &{
            color:${COLORS.darkGray} !important;
        }
        &[disabled]{
            color:#dbdbdb !important;
        }
        .MuiSvgIcon-root{
            font-size:3rem !important;
        }
    `
    return (
        <div flexDirection="row" justifyContent="space-around" style={{ position: "absolute", height: "100%", width: props.width < 1000 ? "70%" : "100%", display: "flex", flexDirection: "row", alignItems: "center", pointerEvents: "none" }}>
            <Back><ChevronLeftIcon></ChevronLeftIcon></Back>
            <Next><ChevronRightIcon></ChevronRightIcon></Next>
        </div>
    )
}

export default VideosArrows;