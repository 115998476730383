import React from 'react'
import {COLORS,FONTS, Row} from "../styles/styles"
import Footer from "./footer"
import WarningIcon from '@material-ui/icons/Warning';
import styled from "styled-components"

const PageNotFound = () => {
    return(
        <div style={{backgroundColor:COLORS.brightGray}}>
            <Row flexDirection="column" justifyContent="center" padding="15% 0">
                <WarningIcon style={{fontSize:"5rem",color:COLORS.brightGreen,margin:"auto"}}></WarningIcon>
                <h1 style={{margin:"20px auto",color:COLORS.darkGreen,fontFamily:FONTS.title}}>Página não encontrada!</h1>
            </Row>
            <Footer></Footer>
        </div>
    );
}

export default PageNotFound;