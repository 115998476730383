import React from "react";
import { Row, COLORS, SectionTitle, SectionText, VideosCard } from "../styles/styles"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ServiceBox from '../components/serviceBox'
import Container from './container'
import { UseViewport } from '../hooks/viewportProvider'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import VideosArrows from './videosArrows'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from "styled-components";
import 'pure-react-carousel/dist/react-carousel.es.css';

const VideosContainer = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    max-width:100%;
    overflow:hidden;
    max-height:auto;
`

const SSlide = styled(Slide)`
    display:flex;

    padding-bottom: 0 !important;
    height:auto !important;

    & > div{
        width: auto !important;
        height: auto !important;
        position: relative !important;
    }

`

const SliderEdited = styled(Slider)`
    div{
        outline:none;
    }
`

function Services() {
    const [services, setServices] = React.useState([])

    React.useEffect(() => {
        fetch("http://sejabroker.com.br/api/get_all_courses.php")
            .then(response => response.json())
            .then(responseJson => {
                console.log(responseJson)
                setServices(responseJson)
            })
    }, [])

    const { width } = UseViewport()

    return (
        <Container width={width < 1200 ? (width < 1000 ? "98%" : "1000px") : "1200px"} id="services" padding="100px 0" backgroundColor={COLORS.brightGray}>
            <Row flexDirection={width < 990 ? "column" : "row"} justifyContent="center" alignItems="center">
                <SectionTitle width="100%" color={COLORS.darkGray}>Cursos desenvolvidos pela Bolsa Brasileira de Mercadorias</SectionTitle>
            </Row>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={services.length}
                style={{
                    'margin': "100px auto 0 auto",
                    'padding': "40px",
                    'border-radius': "15px"
                }}
            >
                <SliderEdited style={{ height: "auro" }}>

                    {
                        services.map(
                            (service, index) => {
                                if (width > 1000) {
                                    console.log(index)
                                    return (
                                        <SSlide key={index} index={index}>
                                            <VideosContainer>
                                                {
                                                    services[index - 1]
                                                        ?
                                                        <ServiceBox
                                                            opacity={0.05}
                                                            width={width > 1000 ? "20%" : "100%"}
                                                            video={services[index - 1].video}
                                                            name={services[index - 1].name}
                                                            image={services[index - 1].image}
                                                            description={services[index - 1].description}
                                                            link={services[index - 1].link}
                                                        ></ServiceBox>
                                                        :
                                                        <div style={{ width: width > 1000 ? "20%" : "100%" }}>
                                                        </div>
                                                }
                                                <ServiceBox
                                                    width={width > 1000 ? "33%" : "100%"}
                                                    video={services[index].video}
                                                    name={services[index].name}
                                                    image={services[index].image}
                                                    description={services[index].description}
                                                    link={services[index].link}
                                                ></ServiceBox>
                                                {
                                                    services[index + 1]
                                                        ?
                                                        <ServiceBox
                                                            opacity={0.05}
                                                            width={width > 1000 ? "20%" : "100%"}
                                                            video={services[index + 1].video}
                                                            name={services[index + 1].name}
                                                            image={services[index + 1].image}
                                                            description={services[index + 1].description}
                                                            link={services[index + 1].link}
                                                        ></ServiceBox>
                                                        :
                                                        <div style={{ width: width > 1000 ? "20%" : "100%" }}>
                                                        </div>

                                                }
                                                <VideosArrows width={width}></VideosArrows>
                                            </VideosContainer>
                                        </SSlide>
                                    )
                                } else {
                                    return (
                                        <SSlide key={index} index={index}>
                                            <VideosContainer>
                                                <ServiceBox
                                                    width={width > 1000 ? "33%" : "50%"}
                                                    video={services[index].video}
                                                    name={services[index].name}
                                                    image={services[index].image}
                                                    description={services[index].description}
                                                    link={services[index].link}
                                                ></ServiceBox>
                                                <VideosArrows width={width}></VideosArrows>
                                            </VideosContainer>
                                        </SSlide>
                                    )
                                }

                            }
                        )
                    }

                </SliderEdited>
            </CarouselProvider>
        </Container>
    )
}

export default Services;