import React from "react";
import Menu from "../components/menu";
import Banner from "../components/banner";
import WhatsappIcon from "../components/whatsappIcon"
import Contact from '../components/contact'
import Footer from '../components/footer'
import { UseViewport } from '../hooks/viewportProvider'
import MobileMenu from '../components/mobileMenu'
import BackTop from "../components/backToTop"
import About from "../components/about"
import Services from "../components/services"
import SejaBroker from "../components/sejaBroker"


function Home() {
  const { width } = UseViewport();
  return (
    <div>
      {width < 990 ? <MobileMenu></MobileMenu> : <Menu></Menu>}
      <Banner windowWidth={width}></Banner>
      <Services></Services>
      <Contact></Contact>
      <WhatsappIcon></WhatsappIcon>
      <Footer></Footer>
    </div>
  );

}

export default Home;
