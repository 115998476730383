import React from "react";
import styled from "styled-components";
import {
  handleCpfChange,
  handleIdhange,
  handleDateChange,
} from "../utils/formFunctions";

const SocioForm = ({ formik, socioNumber, handleFinished, title }) => {
  return (
    <Table>
      <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
        <th colspan="6">Dados Cadastrais do {title ? title : "Sócio " + socioNumber}</th>
      </tr>
      <tr>
        <th colspan="3">
          <label htmlFor={"socio" + socioNumber + "_name"}>Nome Completo</label>
        </th>
        <th colspan="3">
          <label htmlFor={"socio" + socioNumber + "_profession"}>
            Profissão
          </label>
        </th>
      </tr>
      <tr>
        <td colspan="3">
          <Input
            htmlFor={"socio" + socioNumber + "_name"}
            name={"socio" + socioNumber + "_name"}
            value={formik.values["socio" + socioNumber + "_name"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td colspan="3">
          <Input
            htmlFor={"socio" + socioNumber + "_profession"}
            name={"socio" + socioNumber + "_profession"}
            value={formik.values["socio" + socioNumber + "_profession"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
            value={formik.values.profession}
          />
        </td>
      </tr>

      <tr>
        <th>
          <label htmlFor={"socio" + socioNumber + "_data_de_nascimento"}>
            Data de Nascimento
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_nacionalidade"}>
            Nacionalidade
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_local_de_nascimento"}>
            Local de Nascimento
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_uf"}>UF</label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_estado_civil"}>
            Estado Civil
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_sexo"}>Sexo</label>
        </th>
      </tr>

      <tr>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_data_de_nascimento"}
            name={"socio" + socioNumber + "_data_de_nascimento"}
            value={formik.values["socio" + socioNumber + "_data_de_nascimento"]}
            type="text"
            onChange={(event) => { handleDateChange(event, formik); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_nacionalidade"}
            name={"socio" + socioNumber + "_nacionalidade"}
            value={formik.values["socio" + socioNumber + "_nacionalidade"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_local_de_nascimento"}
            name={"socio" + socioNumber + "_local_de_nascimento"}
            value={
              formik.values["socio" + socioNumber + "_local_de_nascimento"]
            }
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_uf"}
            name={"socio" + socioNumber + "_uf"}
            value={formik.values["socio" + socioNumber + "_uf"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_estado_civil"}
            name={"socio" + socioNumber + "_estado_civil"}
            value={formik.values["socio" + socioNumber + "_estado_civil"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_sexo"}
            name={"socio" + socioNumber + "_sexo"}
            value={formik.values["socio" + socioNumber + "_sexo"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_cpf"}>CPF</label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_identidade"}>
            Nº do Documento de Identidade
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_data_emissao"}>
            Data de Emissão
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_orgao_emissor"}>
            Órgão Emissor
          </label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_uf_emissor"}>
            UF-Emissor
          </label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_cpf"}
            name={"socio" + socioNumber + "_cpf"}
            value={formik.values["socio" + socioNumber + "_cpf"]}
            type="text"
            onChange={(event) => { handleCpfChange(event, formik); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_identidade"}
            name={"socio" + socioNumber + "_identidade"}
            value={formik.values["socio" + socioNumber + "_identidade"]}
            type="text"
            onChange={(event) => { handleIdhange(event, formik); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_data_emissao"}
            name={"socio" + socioNumber + "_data_emissao"}
            value={formik.values["socio" + socioNumber + "_data_emissao"]}
            type="text"
            onChange={(event) => { handleDateChange(event, formik); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_orgao_emissor"}
            name={"socio" + socioNumber + "_orgao_emissor"}
            value={formik.values["socio" + socioNumber + "_orgao_emissor"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_uf_emissor"}
            name={"socio" + socioNumber + "_uf_emissor"}
            value={formik.values["socio" + socioNumber + "_uf_emissor"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_endereco_residencial"}>
            Endereço Residencial (Logradouro)
          </label>
        </th>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_numero"}>Número</label>
        </th>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_complemento"}>
            Complemento
          </label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_endereco_residencial"}
            name={"socio" + socioNumber + "_endereco_residencial"}
            value={
              formik.values["socio" + socioNumber + "_endereco_residencial"]
            }
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_numero"}
            name={"socio" + socioNumber + "_numero"}
            value={formik.values["socio" + socioNumber + "_numero"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_complemento"}
            name={"socio" + socioNumber + "_complemento"}
            value={formik.values["socio" + socioNumber + "_complemento"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_bairro"}>Bairro</label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_cidade"}>Cidade</label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_uf"}>UF</label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_pais"}>País</label>
        </th>
        <th>
          <label htmlFor={"socio" + socioNumber + "_cep"}>CEP</label>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_bairro"}
            name={"socio" + socioNumber + "_bairro"}
            value={formik.values["socio" + socioNumber + "_bairro"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_cidade"}
            name={"socio" + socioNumber + "_cidade"}
            value={formik.values["socio" + socioNumber + "_cidade"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_uf"}
            name={"socio" + socioNumber + "_uf"}
            value={formik.values["socio" + socioNumber + "_uf"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_pais"}
            name={"socio" + socioNumber + "_pais"}
            value={formik.values["socio" + socioNumber + "_pais"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td>
          <Input
            htmlFor={"socio" + socioNumber + "_cep"}
            name={"socio" + socioNumber + "_cep"}
            value={formik.values["socio" + socioNumber + "_cep"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_telefone"}>
            Telefone (DDD + Número)
          </label>
        </th>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_fax"}>
            Fax (DDD + Número)
          </label>
        </th>
        <th colspan="2">
          <label htmlFor={"socio" + socioNumber + "_email"}>E-mail</label>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_telefone"}
            name={"socio" + socioNumber + "_telefone"}
            value={formik.values["socio" + socioNumber + "_telefone"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_fax"}
            name={"socio" + socioNumber + "_fax"}
            value={formik.values["socio" + socioNumber + "_fax"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor={"socio" + socioNumber + "_email"}
            name={"socio" + socioNumber + "_email"}
            value={formik.values["socio" + socioNumber + "_email"]}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event) }}
          />
        </td>
      </tr>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  th,
  td {
    margin: 0;
  }
  th {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
`;

export default SocioForm;
