import React from 'react';
import fab from "@material-ui/core/Fab"
import whatsAppIcon from '@material-ui/icons/WhatsApp';
import styled from "styled-components"
import tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import { COLORS } from "../styles/styles"
import { UseViewport } from '../hooks/viewportProvider'


const Fab = styled(fab)`
    position:fixed !important;
    right:0;
    bottom:0;
    margin-right:30px !important;
    margin-bottom:30px !important;
    height:58px !important;
    padding:0 20px !important;
    background-color:#23bc5c !important;
    color:white !important;
    z-index:100;
`

const WhatsAppIcon = styled(whatsAppIcon)`
    font-size:30px !important;
`

const styles = {
    tooltip: {
        backgroundColor: COLORS.brightGray,
        color: COLORS.darkGray,
        padding: "10px",
        fontSize: "0.8rem",
        boxShadow: "1px 1px 6px 0px rgba(51,51,51,0.30)",
    }
};

const CustomTooltip = withStyles(styles)(tooltip);


const WhatsappIcon = () => {
    const { width } = UseViewport();
    if (width < 990) {
        return (
            <Fab aria-label="whatsapp" size="large" variant="extended" href="https://api.whatsapp.com/send?phone=5521999243983">
                <WhatsAppIcon></WhatsAppIcon>
            </Fab>
        );

    } else {
        return (
            <CustomTooltip id="aklwdaowi" open={true} title="Nos envie uma mensagem por whatsapp" placement="top-start" arrow>
                <Fab aria-label="whatsapp" size="large" variant="extended" target="_blank" href="https://api.whatsapp.com/send?phone=5521999243983" >
                    <WhatsAppIcon></WhatsAppIcon>
                </Fab>
            </CustomTooltip>
        );

    }

}

export default WhatsappIcon;