import React from "react";
import styled from "styled-components";
import { handleCnpjChange } from "../utils/formFunctions";

const EmpresaForm = ({ formik, empresaFinished, setEmpresaFinished, handleFinished }) => {
  return (
    <Table>
      <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
        <th colspan="5">
          <span style={{ marginRight: "30px" }}>
            Dados Cadastrais da Empresa do Parceiro:{" "}
          </span>
          <input
            id="socio_check"
            name="socio_procurador"
            type="radio"
            value="socio"
            onChange={formik.handleChange}
            checked={formik.values.socio_procurador === "socio"}
          ></input>
          <label style={{ marginRight: "20px" }} htmlFor="socio">
            Sou sócio
          </label>
          <input
            id="procurador_check"
            name="socio_procurador"
            type="radio"
            value="procurador"
            onChange={formik.handleChange}
            checked={formik.values.socio_procurador === "procurador"}
          ></input>
          <label htmlFor="procurador_check"> Sou procurador</label>
        </th>
      </tr>

      <tr>
        <th colspan="3">
          <label htmlFor="empresa_nome">
            Empresa em que é sócio / trabalha
          </label>
        </th>
        <th colspan="2">
          <label htmlFor="empresa_ramo">Ramo de Atividade</label>
        </th>
      </tr>
      <tr>
        <td colspan="3">
          <Input
            htmlFor="empresa_nome"
            name="empresa_nome"
            value={formik.values.empresa_nome}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="empresa_ramo"
            name="empresa_ramo"
            value={formik.values.empresa_ramo}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
            value={formik.values.empresa_ramo}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="empresa_cnpj">CNPJ</label>
        </th>
        <th colspan="2">
          <label htmlFor="empresa_inscricao">
            Inscrição Municipal
          </label>
        </th>
        <th>
          <label htmlFor="empresa_data_fundacao">
            Data de Fundação / Constituição
          </label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor="empresa_cnpj"
            name="empresa_cnpj"
            value={formik.values.empresa_cnpj}
            type="text"
            onChange={(event) => { handleCnpjChange(event, formik); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="empresa_inscricao"
            name="empresa_inscricao"
            value={formik.values.empresa_inscricao}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="empresa_data_fundacao"
            name="empresa_data_fundacao"
            value={formik.values.empresa_data_fundacao}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="empresa_endereco">
            Endereço Residencial (Logradouro)
          </label>
        </th>
        <th>
          <label htmlFor="empresa_numero">Número</label>
        </th>
        <th colspan="2">
          <label htmlFor="empresa_complemento">Complemento</label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor="empresa_endereco"
            name="empresa_endereco"
            value={formik.values.empresa_endereco}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="empresa_numero"
            name="empresa_numero"
            value={formik.values.empresa_numero}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="empresa_complemento"
            name="empresa_complemento"
            value={formik.values.empresa_complemento}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th>
          <label htmlFor="empresa_bairro">Bairro</label>
        </th>
        <th>
          <label htmlFor="empresa_cidade">Cidade</label>
        </th>
        <th>
          <label htmlFor="empresa_uf">UF</label>
        </th>
        <th>
          <label htmlFor="empresa_pais">País</label>
        </th>
        <th>
          <label htmlFor="empresa_cep">CEP</label>
        </th>
      </tr>
      <tr>
        <td>
          <Input
            htmlFor="empresa_bairro"
            name="empresa_bairro"
            value={formik.values.empresa_bairro}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="empresa_cidade"
            name="empresa_cidade"
            value={formik.values.empresa_cidade}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="empresa_uf"
            name="empresa_uf"
            value={formik.values.empresa_uf}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="empresa_pais"
            name="empresa_pais"
            value={formik.values.empresa_pais}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="empresa_cep"
            name="empresa_cep"
            value={formik.values.empresa_cep}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, 'empresa_', empresaFinished, setEmpresaFinished) }}
          />
        </td>
      </tr>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  th,
  td {
    margin: 0;
  }
  th {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
`;

export default EmpresaForm;
