import React from "react";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import logo from '../images/SejaBroker.png'
import styled from "styled-components";
import InstagramIcon from "@material-ui/icons/Instagram";
import FaceIcon from "@material-ui/icons/Facebook";
import menuicon from "@material-ui/icons/Menu";
import { COLORS } from "../styles/styles";
import { FONTS } from "../styles/styles";
import drawer from '@material-ui/core/Drawer'
import menulist from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

const MenuList = styled(menulist)`
`;

const MenuLink = styled(MenuItem)`
  color: ${COLORS.darkGray};
  text-decoration: none !important;
  font-family: ${FONTS.menu};
  padding:20px 80px;
`;

const TopBar = styled(ToolBar)`
  background: white;
  padding: 10px 0;
  flex-direction: row;
  justify-content: space-between;

  * {
    padding: 0 50px;
  }
`;

const Span = styled.span`
  color: ${COLORS.darkGreen};
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    color: ${COLORS.brightGreen};
  }

  svg {
    font-size: 1.7rem;
  }
`;

const MenuIcon = styled(menuicon)`
  color: ${COLORS.darkGreen};
  font-size:30px;
  cursor:pointer;
`

const Drawer = styled(drawer)`
  
`


const MobileMenu = () => {
  const [menuState, setMenuState] = React.useState(false)



  const toggleDrawer = () => {
    menuState ? setMenuState(false) : setMenuState(true)
  }

  const handleClick = (id) => {
    toggleDrawer();
    window.location.href = id;
  }


  return (
    <div>
      <AppBar position="static">
        <TopBar>
          <img id="logo" width="150px" src={logo}></img>
          <MenuIcon onClick={toggleDrawer}></MenuIcon>
        </TopBar>
      </AppBar>

      <Drawer open={menuState} onClose={toggleDrawer} anchor="right">
        <MenuList>
          <MenuLink onClick={() => { handleClick("#services") }}>Cursos</MenuLink>
          <MenuLink onClick={() => { handleClick("#contact") }}>Matricule-se</MenuLink>
        </MenuList>
      </Drawer>
    </div>

  );
};

export default MobileMenu;
