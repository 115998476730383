import React from 'react'
import { Row, COLORS, FONTS, SectionTitle, SectionText, FormContainer } from "../styles/styles"
import ContactForm from "./contactForm"
import Container from './container'
import { UseViewport } from '../hooks/viewportProvider'
import whatsAppIcon from '@material-ui/icons/WhatsApp';
import phoneIcon from '@material-ui/icons/Phone';
import styled from "styled-components"

const WhatsAppIcon = styled(whatsAppIcon)`
    color:white !important;
    font-size:30px !important;
`

const PhoneIcon = styled(phoneIcon)`
    color:white !important;
    font-size:30px !important;
`

const Contact = () => {
    const { width } = UseViewport();

    return (
        <Container width={width < 1200 ? (width < 990 ? "98%" : "990px") : "1200px"} id="contact" padding="50px 0" backgroundColor="linear-gradient(0deg, rgba(139,227,67,1) 0%, rgba(0,179,68,1) 100%)">
            <Row flexDirection="column" alignItems="center" justifyContent="center">
                <SectionTitle width="auto" color={COLORS.darkGreen}>Quero começar o meu curso agora!</SectionTitle>
                <SectionText width="auto" color={COLORS.darkGreen} margin="5px 0 0 0">Preencha o formulário abaixo e entraremos em contato.</SectionText>
            </Row>
            <Row justifyContent="center" flexDirection="row" style={{ marginTop: "50px" }}>
                <FormContainer>
                    <ContactForm></ContactForm>
                </FormContainer>
            </Row>
        </Container>

    );
}

export default Contact;