import React from 'react';
import fab from "@material-ui/core/Fab"
import styled from "styled-components"
import {COLORS} from "../styles/styles"
import expandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from "react-scroll";


const Fab = styled(fab)`
    position:fixed !important;
    left:0;
    bottom:0;
    margin-left:30px !important;
    margin-bottom:30px !important;
    height:58px !important;
    padding:0 20px !important;
    background-color:rgba(3, 47, 25, 0.80) !important;
    color:white !important;
    z-index:100;
`

const ExpandLessIcon = styled(expandLessIcon)`
    font-size:30px !important;
`

const styles = {
    tooltip: {
        backgroundColor: COLORS.brightGray,
        color: COLORS.darkGray,
        padding:"10px",
        fontSize:"0.8rem",
        boxShadow: "1px 1px 6px 0px rgba(51,51,51,0.30)",
    }
};

const handleBackToTop = () => {
    alert("Clicou!");
}


const WhatsappIcon = () => {
    return(
        <Link to="logo" smooth={true}>
            <Fab aria-label="whatsapp" size="large" variant="extended">
                <ExpandLessIcon></ExpandLessIcon>
            </Fab>
        </Link>
    );
    
}

export default WhatsappIcon;