import React from 'react';
import { COLORS } from '../styles/styles'
import styled from 'styled-components'
import BannerLeft from './bannerLeft'
import BannerRight from './bannerRight'
import SejaBrokerImage from '../images/seja-broker-background.jpg'

const Banner = (props) => {

    const Container = styled.div`
        width:100%;
        height:${props.windowWidth > 990 ? "700px" : "400px"};
        background:rgba(35, 97, 25, 0.9);
    `

    const ContainerBg = styled.div`
        background:url(${SejaBrokerImage});
        background-repeat: no-repeat;
        background-size: cover;
    `

    return (
        <ContainerBg>

            {
                props.windowWidth > 990
                    ?
                    <Container>
                        <BannerLeft></BannerLeft>
                        <BannerRight windowWidth={props.windowWidth}></BannerRight>
                    </Container>
                    :
                    <Container>
                        <BannerRight windowWidth={props.windowWidth}></BannerRight>
                    </Container>
            }
        </ContainerBg>
    );
}



export default Banner;