import React from "react";
import { Link } from "react-scroll";
import Container from './container'
import { Row } from '../styles/styles'
import SejaBrokerLogo from '../images/SejaBroker.png'
import styled from 'styled-components'
import { COLORS, FONTS } from '../styles/styles'


const Menu = () => {

    return (
        <Background>
            <Container width="990px">
                <Row justifyContent="space-between">
                    <Logo width={250} src={SejaBrokerLogo}></Logo>
                    <MenuList>
                        <Link to="services" smooth={true} style={{ cursor: "pointer" }}>Cursos</Link>
                        <Link to="contact" smooth={true} style={{ cursor: "pointer" }}>Matricule-se</Link>
                    </MenuList>
                </Row>
            </Container>
        </Background>
    );

}

const MenuList = styled.div`
    width:300px;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    font-family:${FONTS.montserrat};
    font-size:1rem;
    font-weight:700;
    color:${COLORS.brightGray};
`

const Background = styled.div`
    position: absolute;
    top:0;
    width:100%;
    padding:30px 0;
    height:70px;
`

const Logo = styled.img`
    
`


export default Menu;