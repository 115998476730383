import React from 'react'
import styled from 'styled-components'

function Container(props) {
    const Container = styled.div`
        width: ${props.width};
        padding: ${props.padding};
        margin:auto;
    `
    return (
        <div id={props.id} style={{ background: props.backgroundColor, backgroundImage: props.backgroundColor }}>
            <Container>{props.children}</Container>
        </div>
    )
}

export default Container;