import { ExploreOffOutlined } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import {
  handleCpfChange,
  handleIdhange,
  handleDateChange,
} from "../utils/formFunctions";

const numeros = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const ParceiroForm = ({ formik, parceiroFinished, setParceiroFinished, handleFinished }) => {
  return (
    <Table>
      <tr style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
        <th colspan="6">Dados Cadastrais do Parceiro</th>
      </tr>
      <tr>
        <th colspan="3">
          <label htmlFor="parceiro_nome">Nome Completo</label>
        </th>
        <th colspan="3">
          <label htmlFor="parceiro_profissao">Profissão</label>
        </th>
      </tr>
      <tr>
        <td colspan="3">
          <Input
            htmlFor="parceiro_nome"
            name="parceiro_nome"
            value={formik.values.parceiro_nome}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td colspan="3">
          <Input
            htmlFor="parceiro_profissao"
            name="parceiro_profissao"
            value={formik.values.parceiro_profissao}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th>
          <label htmlFor="parceiro_data_de_nascimento">
            Data de Nascimento
          </label>
        </th>
        <th>
          <label htmlFor="parceiro_nacionalidade">Nacionalidade</label>
        </th>
        <th>
          <label htmlFor="parceiro_local_de_nascimento">
            Local de Nascimento
          </label>
        </th>
        <th>
          <label htmlFor="parceiro_uf">UF</label>
        </th>
        <th>
          <label htmlFor="parceiro_estado_civil">Estado Civil</label>
        </th>
        <th>
          <label htmlFor="parceiro_sexo">Sexo</label>
        </th>
      </tr>

      <tr>
        <td>
          <Input
            htmlFor="parceiro_data_de_nascimento"
            name="parceiro_data_de_nascimento"
            value={formik.values.parceiro_data_de_nascimento}
            type="text"
            onChange={(event) => { handleDateChange(event, formik); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_nacionalidade"
            name="parceiro_nacionalidade"
            value={formik.values.parceiro_nacionalidade}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_local_de_nascimento"
            name="parceiro_local_de_nascimento"
            value={formik.values.parceiro_local_de_nascimento}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_nascimento_uf"
            name="parceiro_nascimento_uf"
            value={formik.values.parceiro_nascimento_uf}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_estado_civil"
            name="parceiro_estado_civil"
            value={formik.values.parceiro_estado_civil}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_sexo"
            name="parceiro_sexo"
            value={formik.values.parceiro_sexo}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="parceiro_cpf">CPF</label>
        </th>
        <th>
          <label htmlFor="parceiro_identidade">
            Nº do Documento de Identidade
          </label>
        </th>
        <th>
          <label htmlFor="parceiro_data_emissao">Data de Emissão</label>
        </th>
        <th>
          <label htmlFor="parceiro_orgao_emissor">Órgão Emissor</label>
        </th>
        <th>
          <label htmlFor="parceiro_uf_emissor">UF-Emissor</label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor="parceiro_cpf"
            name="parceiro_cpf"
            value={formik.values.parceiro_cpf}
            type="text"
            onChange={(event) => { handleCpfChange(event, formik); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_identidade"
            name="parceiro_identidade"
            value={formik.values.parceiro_identidade}
            type="text"
            onChange={(event) => { handleIdhange(event, formik); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_data_emissao"
            name="parceiro_data_emissao"
            value={formik.values.parceiro_data_emissao}
            type="text"
            onChange={(event) => { handleDateChange(event, formik); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_orgao_emissor"
            name="parceiro_orgao_emissor"
            value={formik.values.parceiro_orgao_emissor}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_uf_emissor"
            name="parceiro_uf_emissor"
            value={formik.values.parceiro_uf_emissor}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="parceiro_endereco_residencial">
            Endereço Residencial (Logradouro)
          </label>
        </th>
        <th colspan="2">
          <label htmlFor="parceiro_numero">Número</label>
        </th>
        <th colspan="2">
          <label htmlFor="parceiro_complemento">Complemento</label>
        </th>
      </tr>

      <tr>
        <td colspan="2">
          <Input
            htmlFor="parceiro_endereco_residencial"
            name="parceiro_endereco_residencial"
            value={formik.values.parceiro_endereco_residencial}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="parceiro_numero"
            name="parceiro_numero"
            value={formik.values.parceiro_numero}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="parceiro_complemento"
            name="parceiro_complemento"
            value={formik.values.parceiro_complemento}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="parceiro_bairro">Bairro</label>
        </th>
        <th>
          <label htmlFor="parceiro_cidade">Cidade</label>
        </th>
        <th>
          <label htmlFor="parceiro_uf">UF</label>
        </th>
        <th>
          <label htmlFor="parceiro_pais">País</label>
        </th>
        <th>
          <label htmlFor="parceiro_cep">CEP</label>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          <Input
            htmlFor="parceiro_bairro"
            name="parceiro_bairro"
            value={formik.values.parceiro_bairro}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_cidade"
            name="parceiro_cidade"
            value={formik.values.parceiro_cidade}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_uf"
            name="parceiro_uf"
            value={formik.values.parceiro_uf}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_pais"
            name="parceiro_pais"
            value={formik.values.parceiro_pais}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td>
          <Input
            htmlFor="parceiro_cep"
            name="parceiro_cep"
            value={formik.values.parceiro_cep}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
      </tr>

      <tr>
        <th colspan="2">
          <label htmlFor="parceiro_telefone">Telefone (DDD + Número)</label>
        </th>
        <th colspan="2">
          <label htmlFor="parceiro_fax">Fax (DDD + Número)</label>
        </th>
        <th colspan="2">
          <label htmlFor="parceiro_email">E-mail</label>
        </th>
      </tr>
      <tr>
        <td colspan="2">
          <Input
            htmlFor="parceiro_telefone"
            name="parceiro_telefone"
            value={formik.values.parceiro_telefone}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="parceiro_fax"
            name="parceiro_fax"
            value={formik.values.parceiro_fax}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
        <td colspan="2">
          <Input
            htmlFor="parceiro_email"
            name="parceiro_email"
            value={formik.values.parceiro_email}
            type="text"
            onChange={(event) => { formik.handleChange(event); handleFinished(event, "parceiro_", parceiroFinished, setParceiroFinished) }}
          />
        </td>
      </tr>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  th,
  td {
    margin: 0;
  }
  th {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
`;

export default ParceiroForm;
